<div *ngIf="!loginDisplay">
    <p class="welcome">Megahertz Monitoring de certificats SSL</p>
    <p>Merci de vous connecter avec votre compte office365</p>
</div>

<div *ngIf="loginDisplay">
    <div class="row">
        <div class="col-sm">
            <h1>Certificat SSL</h1>
            <div *ngFor="let eventCal of sortData">
                <mat-card class="card" *ngIf="eventCal.subject.includes('Certificat')" [ngClass]="getClassColor(eventCal)">
                    <mat-card-title>{{eventCal.subject}}</mat-card-title>
                    <mat-card-subtitle>{{eventCal.start.dateTime | date : 'dd.MM.yyyy'}}</mat-card-subtitle>
                </mat-card>
            </div>
        </div>
        <div class="col-sm">
            <h1>Office 365</h1>
            <div *ngFor="let eventCal of sortData">
                <mat-card class="card col-sm" *ngIf="eventCal.subject.includes('Office')" [ngClass]="getClassColor(eventCal)">
                    <mat-card-title>{{eventCal.subject}}</mat-card-title>
                    <mat-card-subtitle>{{eventCal.start.dateTime | date : 'dd.MM.yyyy'}}</mat-card-subtitle>
                </mat-card>
            </div>
        </div>
        <div class="col-sm">
            <h1>3CX</h1>
            <div *ngFor="let eventCal of sortData">
                <mat-card class="card col-sm" *ngIf="eventCal.subject.includes('3CX')" [ngClass]="getClassColor(eventCal)">
                    <mat-card-title>{{eventCal.subject}}</mat-card-title>
                    <mat-card-subtitle>{{eventCal.start.dateTime | date : 'dd.MM.yyyy'}}</mat-card-subtitle>
                </mat-card>
            </div>
        </div>
        <div class="col-sm">
            <h1>Sophos</h1>
            <div *ngFor="let eventCal of sortData">
                <mat-card class="card col-sm" *ngIf="eventCal.subject.includes('Sophos')" [ngClass]="getClassColor(eventCal)">
                    <mat-card-title>{{eventCal.subject}}</mat-card-title>
                    <mat-card-subtitle>{{eventCal.start.dateTime | date : 'dd.MM.yyyy'}}</mat-card-subtitle>
                </mat-card>
            </div>
        </div>
      <div class="col-sm">
        <h1>Azure</h1>
        <div *ngFor="let eventCal of sortData">
          <mat-card class="card" *ngIf="eventCal.subject.includes('Azure')" [ngClass]="getClassColor(eventCal)">
            <mat-card-title>{{eventCal.subject}}</mat-card-title>
            <mat-card-subtitle>{{eventCal.start.dateTime | date : 'dd.MM.yyyy'}}</mat-card-subtitle>
          </mat-card>
        </div>
      </div>

    </div>

</div>
