<mat-toolbar color="primary">
  <a class="title" href="/">{{ title }}</a>

  <div class="toolbar-spacer"></div>

  <a mat-button [routerLink]="['profile']">Profile</a>

  <button mat-raised-button [matMenuTriggerFor]="loginMenu" *ngIf="!loginDisplay">Login</button>
  <mat-menu #loginMenu="matMenu">
    <button mat-menu-item (click)="loginRedirect()">Login using Redirect</button>
    <button mat-menu-item (click)="loginPopup()">Login using Popup</button>
  </mat-menu>

  <button mat-raised-button [matMenuTriggerFor]="logoutMenu" *ngIf="loginDisplay">Logout</button>
  <mat-menu #logoutMenu="matMenu">
    <button mat-menu-item (click)="logout()">Logout using Redirect</button>
    <button mat-menu-item (click)="logout(true)">Logout using Popup</button>
  </mat-menu>

</mat-toolbar>
<div class="container">
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>
